<template>
  <div class="form-multi-select">
    <select :id="id" class="form-control" v-model="state.title" :size="$env.device === 'mobile' ? false : computedItems.length + 1" :style="styles" @dblclick="dblclick()" @change="update(state.title)" :disabled="disabled">
      <option :value="i.title" v-for="(i, idx) in computedItems" :key="idx">{{ i.title }}</option>
    </select>
    <div class="actions clearfix">
      <div class="left float-left">
        <div class="btn-group" role="group">
          <button class="btn" type="button" title="위로" @click="move(true)" :disabled="!state.title || isEnd(true)">
            <i class="fa fa-angle-up"></i>
          </button>
          <button class="btn" type="button" title="아래로" @click="move()" :disabled="!state.title || isEnd()">
            <i class="fa fa-angle-down"></i>
          </button>
        </div>
      </div>
      <div class="right float-right">
        <div class="btn-group" role="group">
          <button class="btn" type="button" title="추가" @click="add()" :disabled="disabled">
            <i class="fa fa-plus"></i>
          </button>
          <button class="btn" type="button" title="수정" @click="edit()" :disabled="!state.title || disabled">
            <i class="fa fa-pencil"></i>
          </button>
          <button class="btn" type="button" title="복제" @click="clone()" :disabled="!state.title || disabled" v-if="cloneable">
            <i class="fa fa-clone"></i>
          </button>
          <button class="btn" type="button" title="삭제" :disabled="!state.title || (role !== 'constraint' && computedItems.length <= 1) || disabled" @click="remove()">
            <i class="fa fa-trash-o"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "componentFormMultiSelect";
  this.initialize = initialize;
  this.checkDuplicatedName = true;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    id: String,
    title: String,
    items: Array,
    styles: Object,
    onChange: Function,
    role: String,
    cloneable: Boolean,
    disabled: Boolean
  },
  setup(props, {emit}) {
    const component = new Component(() => {
      state.title = props.title;
    });

    const state = reactive({
      title: ""
    });

    const TITLE_MESSAGE = "이름을 입력해주세요.";
    const TITLE_MAX_LENGTH = 100;
    const TITLE_MAX_LENGTH_MESSAGE = `이름은 ${TITLE_MAX_LENGTH}자 이하로 입력해주세요.`;
    const TITLE_SAME_MESSAGE = "동일한 이름의 데이터가 존재합니다.";

    const computedItems = computed(() => {
      const items = props.items.filter(s => s.delFlag === "N");

      items.sort((a, b) => {
        if (a.ord < b.ord) {
          return -1;
        } else if (a.ord > b.ord) {
          return 1;
        }

        return 0;
      });

      return items;
    });

    const update = (title) => {
      if (state.title !== title) {
        state.title = title;
      }

      props.title !== undefined && emit("update:title", state.title);
      change();
    };

    const change = () => {
      typeof props.onChange === "function" && props.onChange();
    };

    const add = (item) => {
      const title = item ? item.title : window.prompt(TITLE_MESSAGE)?.trim();
      const items = computedItems.value;

      if (!title) {
        return;
      } else if (title.length > TITLE_MAX_LENGTH) {
        return store.commit("setSwingMessage", TITLE_MAX_LENGTH_MESSAGE);
      } else if (items.some(s => s.title === title)) {
        return store.commit("setSwingMessage", TITLE_SAME_MESSAGE);
      }

      const ord = props.items.length ? (props.items.map(c => c.ord).reduce((prev, current) => prev > current ? prev : current) || 0) + 1 : 1;

      switch (props.role) {
        case "category":
          props.items.push({
            id: null,
            title,
            ord,
            delFlag: "N",
          });
          break;

        case "item": {
          const options = item ? item.options : [];

          for (let i = 0; i < options.length; i += 1) {
            options[i].id = null;
          }

          props.items.push({
            id: null,
            title,
            description: item ? item.desc : null,
            content: item ? item.content : null,
            type: item ? item.type : "text",
            ord,
            required: item ? item.required : true,
            hideTitle: item ? item.hideTitle : false,
            delFlag: "N",
            options,
          });
          break;
        }

        case "option":
          props.items.push({
            id: null,
            title,
            ord,
            itemId: null,
            delFlag: "N",
          });
          break;

        case "constraint":
          props.items.push({
            id: null,
            title,
            ord,
            max: "0",
            children: [],
            delFlag: "N",
            duplicateFlag: "N",
          });
          break;

        case "step":
          props.items.push({
            id: null,
            title,
            ord,
            formId: null,
            delFlag: "N",
          });
          break;
      }

      update(title);
    };

    const edit = () => {
      const title = window.prompt(TITLE_MESSAGE, state.title)?.trim();
      const items = computedItems.value;

      if (!title) {
        return;
      } else if (title.length > TITLE_MAX_LENGTH) {
        return store.commit("setSwingMessage", TITLE_MAX_LENGTH_MESSAGE);
      } else if (title !== state.title && items.some(s => s.title === title)) {
        return store.commit("setSwingMessage", TITLE_SAME_MESSAGE);
      }

      const idx = items.findIndex(i => i.title === state.title);

      if (idx >= 0 && title.length) {
        items[idx].title = title;
      }

      update(title);
    };

    const clone = () => {
      const item = lib.getRenewed(computedItems.value.find(i => i.title === state.title));

      const getTitle = (title) => {
        if (computedItems.value.some(i => i.title === title)) {
          return getTitle(title + " cloned");
        }

        return title;
      };

      item.title = getTitle(item.title + " cloned");
      add(item);
    };

    const move = (up = false) => {
      const items = computedItems.value;
      const idx = items.findIndex(i => i.title === state.title);

      const exchange = (newIdx) => {
        const oldOrd = items[idx].ord;
        const newOrd = items[newIdx].ord;

        items[idx].ord = newOrd;
        items[newIdx].ord = oldOrd;
      };

      exchange(idx + (up ? -1 : 1));
      change();
    };

    const sort = () => {
      for (let i = 0; i < computedItems.value.length; i += 1) {
        computedItems.value[i].ord = i + 1;
      }
    };

    const remove = () => {
      store.commit("confirm", {
        message: "선택하신 데이터를 삭제하시겠습니까?",
        allow() {
          const items = computedItems.value;
          let idx = items.findIndex(i => i.title === state.title);

          if (idx < 0) {
            return;
          }

          items[idx].delFlag = "Y";
          idx = idx - 1;
          update(computedItems.value[idx >= 0 ? idx : 0]?.title || "");
          sort();
        }
      });
    };

    const isEnd = (up = false) => {
      const items = computedItems.value;
      const idx = items.findIndex(i => i.title === state.title);
      const len = items.length;
      return (up && idx === 0) || (!up && idx === len - 1);
    };

    const dblclick = () => {
      if (!state.title) {
        return;
      }

      edit();
    };

    return {component, state, props, computedItems, add, edit, clone, move, remove, isEnd, dblclick, change, update};
  }
});
</script>

<style lang="scss" scoped>
.form-multi-select {
  .actions {
    padding-top: $px9;

    .btn {
      border: 1px solid $colorBorder;
      font-size: $px18;
      padding: $px5 0;
      width: $px40;
      height: $px40;

      &:hover {
        background: $colorBackground;
      }
    }

    .right {
      .btn {
        font-size: $px14;
      }
    }
  }

  @media(max-width: 991px) {
    > select {
      min-height: auto !important;
    }
  }
}
</style>